import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';

const PricePerChew = props => {
  const {
    showPricePer,
    pricePerText,
    productIndex,
    productQuantity,
    showIndex0
  } = props;
  const context = useContext(FunnelContext);
  const { currentCategory, currentTub } = context;

  const currentProduct = `${
    currentCategory === 'subscriptions' ? 'subscription' : 'onetime'
  }_${productIndex}`;

  const { chewsPerQuantity } = currentTub;
  const totalQuantity = parseInt(productQuantity || 1);
  const totalChews = chewsPerQuantity * totalQuantity || 30;
  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });

  const PricePerContainer = () => {
    switch (showPricePer) {
      case 'tub':
        return <>${(discountedPrice / totalQuantity).toFixed(2)}</>;
      default:
      case 'chew':
        return <>${(discountedPrice / totalChews).toFixed(2)}</>;
    }
  };

  if (!showIndex0 && 0 === parseInt(productIndex)) {
    return ``;
  } else {
    return (
      <>
        <PricePerContainer />
        {pricePerText}
      </>
    );
  }
};

export default PricePerChew;
